import requestService from "../../requestService";
import store from '@/store';

export default {
    async createPromocode(form) {
        const response = await requestService.post(`/cp_dash/promocode`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async updatePromocode(id, form) {
        const response = await requestService.post(`/cp_dash/promocode/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async changePromocodeStatus(id, form) {
        const response = await requestService.post(`/cp_dash/promocode/status/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getPromocodes(status, page) {
        const response = await requestService.get(`/cp_dash/promocode${status !== 'All' && status !== undefined && status !== null && status !== '' ? `/status/${status}` : ''}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getPromocodesByStatus(status, page) {
        const response = await requestService.get(`/cp_dash/promocode/status/${status}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async getPromocode(id) {
        const response = await requestService.get(`/cp_dash/promocode/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async restorePromocode(id) {
        const response = await requestService.post(`/cp_dash/promocode/restore/${id}`,{}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
    async deletePromocode(id) {
        const response = await requestService.delete(`/cp_dash/promocode/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
        })
        return response?.data
    },
}