<template>
  <v-col class="px-0 py-0">
    <v-snackbar
      color="success"
      bottom
      v-model="showSuccessSnackbar"
      :timeout="3000"
    >
      <v-row no-gutters align="center">
        <v-icon color="white" style="margin-right: 12px">mdi-check</v-icon>
        Текст успішно скопійовано
      </v-row>
    </v-snackbar>
    <v-row no-gutters align="center" style="margin-top: 40px">
      <div
        class="statusBox"
        @click="activeStatus = 'All'"
        :class="activeStatus == 'All' ? 'activeStatusBox' : ''"
      >
        Усі
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Active'"
        :class="activeStatus == 'Active' ? 'activeStatusBox' : ''"
      >
        Активні
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Paused'"
        :class="activeStatus == 'Paused' ? 'activeStatusBox' : ''"
      >
        Призупинені
      </div>
      <div
        class="statusBox"
        @click="activeStatus = 'Ended'"
        :class="activeStatus == 'Ended' ? 'activeStatusBox' : ''"
      >
        Завершені
      </div>
      <v-row no-gutters align="center" justify="end">
        <submit-button
          style="height: 48px !important; width: max-content; font-size: 16px"
          @click="showCreateNewPromocodeModal = true"
          ><div
            class="adminPlusIcon"
            style="
              margin-right: 8px;
              background-color: white;
              width: 20px;
              height: 20px;
            "
          />
          Новий промокод</submit-button
        >
      </v-row>
    </v-row>
    <v-data-table
      style="margin-top: 32px"
      :loading="loading"
      :headers="headers"
      :items="promocodes"
      hide-default-footer
      hide-default-header
      :items-per-page="100"
      background-color="red"
      no-data-text="Поки що ви не додали жодної знижки"
    >
      <template v-slot:header="{ props }">
        <th
          @click="
            !head.sortable && head.sortable !== false
              ? (sortBy = head.value)((sortDesc = !sortDesc))
              : ''
          "
          v-for="head in props.headers"
          :key="head.id"
          style="padding: 15px"
        >
          <v-row
            no-gutters
            align="center"
            :justify="head.align ? head.align : 'start'"
            ><output
              style="
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.1em;
                color: #1b1b1b;
                cursor: pointer;
                user-select: none;
              "
              >{{ head.text }}
              <v-icon
                v-if="head.sortable !== false"
                size="25px"
                :color="head.value == sortBy ? 'black' : ''"
                >mdi-menu-swap</v-icon
              >
            </output>
          </v-row>
        </th>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-row no-gutters align="center">
          <div
            style="
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 8px;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:#149E51'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:#FFC107'
                : 'background-color:#989898'
            "
          ></div>
          <span
            class="tableItem"
            style="font-size: 16px; font-weight: 500"
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'color: #149e51;'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'color:#FFC107'
                : 'color:#989898'
            "
          >
            {{
              item?.status == "Active" && !item.deleted_at
                ? "Активна"
                : item?.status == "Paused" && !item.deleted_at
                ? "Призупинено"
                : "Завершено"
            }}</span
          >
        </v-row>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <span
          style="font-size: 16px; font-weight: 500"
          :style="item.deleted_at ? 'color:#989898' : ''"
          >{{
            item.translations.find((translate) => translate.lang == "ua")?.title
          }}</span
        >
      </template>
      <template v-slot:[`item.promocode`]="{ item }">
        <v-row no-gutters align="center">
          <v-btn
            icon
            style="margin-right: 8px"
            @click="copyPromocode(item.code)"
            v-if="!item.deleted_at"
          >
            <v-icon color="#4B5262">mdi-content-copy</v-icon>
          </v-btn>
          <span
            style="font-size: 16px; font-weight: 500"
            :style="item.deleted_at ? 'color:#989898' : ''"
            >{{ item.code }}</span
          >
        </v-row>
      </template>
      <template v-slot:[`item.value`]="{ item }">
        <div style="max-width: 160px; margin: 8px 0px">
          <v-row no-gutters justify="center">
            <div
              style="
                padding: 8px;
                border-radius: 10px;
                background: rgba(20, 79, 169, 0.1);
                width: max-content;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                color: #144fa9;
              "
              :style="item.deleted_at ? 'color:#989898' : ''"
            >
              {{ item.amount }}{{ item.amount_type == "Percent" ? "%" : "грн" }}
              {{
                item.route_type == "All"
                  ? "знижки на всі квитки"
                  : item.route_type == "Schedule"
                  ? "знижки на окремі рейси"
                  : "знижки на окремі маршрути"
              }}
            </div>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.period`]="{ item }">
        <v-row no-gutters align="center" justify="center">
          <div
            style="
              border-radius: 10px;
              background: rgba(20, 158, 81, 0.1);
              padding: 4px 8px;
              font-size: 14px;
              font-weight: 500;
              color: #1b1b1b;
              width: max-content;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:rgba(20, 158, 81, 0.10);'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:rgba(255, 193, 7, 0.30);'
                : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;'
            "
          >
            з
            {{
              new Date(item.started_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
          </div>
          <div
            style="
              border-radius: 10px;
              padding: 4px 8px;
              font-size: 14px;
              font-weight: 500;
              color: #1b1b1b;
              width: max-content;
              margin-left: 4px;
            "
            :style="
              item?.status == 'Active' && !item.deleted_at
                ? 'background-color:rgba(223, 58, 58, 0.1);'
                : item?.status == 'Paused' && !item.deleted_at
                ? 'background-color:rgba(255, 193, 7, 0.30);'
                : 'background-color:rgba(149, 157, 165, 0.20);color:#989898;'
            "
          >
            з
            {{
              new Date(item.ended_at).toLocaleDateString("uk-UA", {
                month: "2-digit",
                year: "numeric",
                day: "2-digit",
                timeZone: "Europe/Kiev",
              })
            }}
          </div>
        </v-row>
      </template>
      <template v-slot:[`item.usage`]="{ item }">
        <span style="font-size: 14px; font-style: normal; font-weight: 700"
          >{{ item.usage }}/<span style="color: #989898">{{
            item.usage_count
          }}</span></span
        >
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-row no-gutters justify="end">
          <v-menu offset->
            <template v-slot:activator="{ on, attrs }">
              <div class="iconBackground" v-bind="attrs" v-on="on">
                <div class="dotsVerticalIcon" />
              </div>
            </template>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                width: 180px;
                padding: 20px;
                color: #515151;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              "
              v-if="!item.deleted_at"
            >
              Опції
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 5px" />
              <v-col class="px-0 py-0" style="text-align: left">
                <p
                  style="
                    margin-top: 14px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="setEditPromocode(item)"
                >
                  Редагувати
                </p>
                <!-- <p
                  style="cursor: pointer; user-select: none"
                  @click="$emit('editPromocode', promocode)"
                >
                  Детальніше
                </p> -->
                <p
                  style="cursor: pointer; user-select: none"
                  @click="
                    changePromocodeStatus(
                      item.id,
                      item.status == 'Active' ? 'Paused' : 'Active'
                    )
                  "
                  :style="
                    item.status == 'Active' ? 'color: #ffc107' : 'color:#149E51'
                  "
                >
                  {{ item.status == "Active" ? "Призупинити" : "Поновити" }}
                </p>
                <p
                  style="cursor: pointer; user-select: none; color: red"
                  @click="deletePromocode(item.id)"
                >
                  Видалити
                </p>
              </v-col>
            </div>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                width: 180px;
                padding: 20px;
                color: #515151;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
              "
              v-else
            >
              Опції
              <div style="border-bottom: 1px solid #f2f2f0; margin-top: 5px" />
              <v-col class="px-0 py-0" style="text-align: left">
                <p
                  style="
                    margin-top: 14px;
                    margin-bottom: 16px;
                    cursor: pointer;
                    user-select: none;
                  "
                  @click="setEditPromocode(item)"
                >
                  Редагувати
                </p>
                <p
                  style="cursor: pointer; user-select: none; color: #149e51"
                  @click="restorePromocode(item.id)"
                >
                  Поновити
                </p>
              </v-col>
            </div>
          </v-menu>
        </v-row>
      </template>
    </v-data-table>
    <pagination
      :page="page"
      :quantityPage="quantityPage"
      @changePage="changePage"
    />
    <create-promocode-modal
      v-if="showCreateNewPromocodeModal"
      :visible="showCreateNewPromocodeModal"
      :isEdit="isEdit"
      :promocodeForEdit="promocodeForEdit"
      @deletePromocode="deletePromocode"
      @getPromocodes="getPromocodes"
      @close="
        (showCreateNewPromocodeModal = false),
          (isEdit = false),
          (promocodeForEdit = null)
      "
    />
  </v-col>
</template>
  
  <script>
import promocodeService from "../../../../requests/Admin/Discount/promocodeService";
import submitButton from "../../../UI/Buttons/submitButton.vue";
import Pagination from "../../../UI/pagination.vue";
import CreatePromocodeModal from "./createPromocodeModal.vue";
export default {
  components: { submitButton, CreatePromocodeModal, Pagination },
  data: () => ({
    activeStatus: "All",
    promocodes: [],
    headers: [
      {
        text: "Статус",
        align: "start",
        value: "status",
        sortable: false,
      },
      { text: "Назва", value: "name", align: "center", sortable: false },
      {
        text: "Промокод",
        value: "promocode",
        align: "center",
        sortable: false,
      },
      { text: "Знижка", value: "value", align: "center", sortable: false },
      {
        text: "Період",
        value: "period",
        align: "center",
        sortable: false,
      },
      {
        text: "Використання",
        value: "usage",
        align: "center",
        sortable: false,
      },
      { text: "Опції", value: "action", sortable: false, align: "end" },
    ],
    showCreateNewPromocodeModal: false,
    showSuccessSnackbar: false,
    promocodeForEdit: null,
    isEdit: false,
    loading: true,
    page: 1,
    quantityPage: 0,
  }),
  mounted() {
    this.getPromocodes();
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.loading = true;
      this.getPromocodes();
    },
    copyPromocode(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.showSuccessSnackbar = true;
        })
        .catch((err) => {
          console.log("Something went wrong", err);
        });
    },
    setEditPromocode(promocode) {
      this.isEdit = true;
      this.promocodeForEdit = promocode;
      this.showCreateNewPromocodeModal = true;
    },
    async getPromocodes() {
      await promocodeService
        .getPromocodes(this.activeStatus, this.page)
        .then((res) => {
          if (res.status == "Success") {
            this.promocodes = res.data;
            this.quantityPage = res.pagination.meta.last_page;
            this.loading = false;
          }
        });
    },
    async changePromocodeStatus(id, status) {
      let form = new FormData();
      form.append("status", status);
      await promocodeService.changePromocodeStatus(id, form).then((res) => {
        if (res.status == "Success") {
          this.getPromocodes();
        }
      });
    },
    async restorePromocode(id) {
      await promocodeService.restorePromocode(id).then((res) => {
        if (res.status == "Success") {
          this.getPromocodes();
        }
      });
    },
    async deletePromocode(id) {
      await promocodeService.deletePromocode(id).then((res) => {
        if (res.status == "Success") {
          this.showCreateNewPromocodeModal = false;
          this.isEdit = false;
          this.loading = true;
          this.getPromocodes();
        }
      });
    },
  },
  watch: {
    activeStatus: {
      handler() {
        this.loading = true;
        this.getPromocodes();
      },
    },
  },
};
</script>
  
  <style>
</style>